import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JobTitleService } from "src/app/shared/dummy-data/job-create.service";
import { ApplyJobService } from "src/app/shared/services/apply-job.service";

@Component({
    selector: "app-home-one",
    templateUrl: "./home-one.component.html",
    styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
    category: string = "";
    categoryArray: any[] = [];
    job: string = "";
    countries: any[] = [];

    videos = [
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
        {
            title: "Magnus Recruitment...",
            url: "../../../../assets/img/home-1/magnus.mp4",
            description: "Weekly Job Newsletter",
        },
    ];
  jobInfoListing: any[] = [];
  filteredJobs: any[] = [];
    constructor(
        private router: Router,
        private readonly jobTitleService: JobTitleService,
        private readonly applyJobService:ApplyJobService
    ) {}

    ngOnInit(): void {
        localStorage.removeItem("questions-id");
        this.getCountries();
        this.getAllRecentJobInfo()
    }

    
    getCountries() {
        this.countries = this.jobTitleService.getCountries();
    }

    getAllRecentJobInfo() {
      this.applyJobService.getAllDummyDataOfCountries().subscribe((res: any) => {
        this.jobInfoListing = res?.body || [];
        this.filteredJobs = [...this.jobInfoListing];
        console.log(this.filteredJobs)
        console.log('Job Info Listing:', this.filteredJobs);
      });
    }
  
    filterJobs(jobType: string) {
      if (jobType === 'all') {
        this.filteredJobs = [...this.jobInfoListing]; // Show all jobs
      } else {
        this.filteredJobs = this.jobInfoListing.filter(job =>
          job.jobType.toLowerCase() === jobType.toLowerCase()
        );
      }
    }

    onSelectCountry(country: any) {
      const selectedCountry = country?.value?.country;
      console.log('Selected Country:', selectedCountry);
    
      if (selectedCountry) {
        this.filteredJobs = this.jobInfoListing.filter(
          (job) => job.country?.toLowerCase() === selectedCountry.toLowerCase()
        );
      } else {
        this.filteredJobs = [...this.jobInfoListing];
      }
    }
    


    navigateToCandidateList() {
        this.router.navigate(["/candidate-list"], {
            queryParams: {
                category: this.category,
            },
        });
    }

    navigateToJobList() {
        this.router.navigate(["/job-list"], {
            queryParams: {
                job: this.job,
            },
        });

        // const jobArray = [this.job];
        // this.router.navigate(['/job-list'], {
        //     queryParams: {
        //         job: JSON.stringify(jobArray) // Pass as JSON string
        //     }
        // });
    }

    //ngx-selector-dropDown-Configuration for job category
    config = {
        displayKey: "country",
        search: true,
        height: "auto",
        placeholder: "others",
        customComparator: () => {},
        moreText: "more",
        noResultsFound: "No results found!",
        searchPlaceholder: "Search",
        searchOnKey: "country",
        clearOnSelection: false,
        inputDirection: "ltr"
    };
}
