import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class ApplyJobService {
    constructor(private readonly httpClient: HttpClient) {}

    //get list of questions
    getQuestions() {
        return this.httpClient.get(
            `${environment.baseUrl}/questions-management/get-all-questions`
        );
    }

    //get job by id
    getSpecificJob(id: any) {
        return this.httpClient.get(
            `${environment.baseUrl}/candidate-job-details-management/get-specific-job-info/${id}`
        );
    }

    // job apply
    applyJob(payLoad: any) {
        return this.httpClient.post(
            `${environment.baseUrl}/apply-job-management/add-apply-job-info`,
            payLoad
        );
    }

    //job applications
    jobApplications(
        id: any,
        page: number,
        pageSize: number,
        name: string = "",
        email: string = ""
    ) {
        return this.httpClient.get(
            `${environment.baseUrl}/candidate-job-details-management/fetch-job-applicants/${id}/${page}/${pageSize}?name=${name}&email=${email}`
        );
    }


    //get specific applied job candidate info
    appliedJobInfo(id: any) {
        return this.httpClient.get(
            `${environment.baseUrl}/apply-job-management/get-specific-apply-job-info/${id}`
        );
    }

    //updateJobStatus
    updateJobStatus(id: any, IdOfEmployer: string = '' , IdOfCandidate:any , status: any ) {
        return this.httpClient.patch(
            `${environment.baseUrl}/apply-job-management/update-apply-job-status/${id}?IdOfEmployer=${IdOfEmployer}&IdOfCandidate=${IdOfCandidate}`,
            status
        );
    }
    
    //send contract to client
    sendContractEmail(payload:any ) {
            return this.httpClient.post(
                `${environment.baseUrl}/contract-management/send-contract-email-to-client`,
                payload
            );
        }


    //get all applied jobs candidates
    getAllAppliedJobCandidates(
        page: number,
        pageSize: number,
        name: string = "",
        jobTitle: string = "",
        companyName: string = "",
        assignTo: string = "",
        email: string = ""
    ) {
        return this.httpClient.get(
            `${environment.baseUrl}/apply-job-management/fetch-All-job-applicants/${page}/${pageSize}?name=${name}&jobTitle=${jobTitle}&companyName=${companyName}&assignTo=${assignTo}&email=${email}`
        );
    }

    //create job category
    createJobCategory(payLoad: any) {
        return this.httpClient.post(
            `${environment.baseUrl}/category-management/add-category`,
            payLoad
        );
    }

    //create job category
    createJobSubCategory(payLoad: any) {
        return this.httpClient.post(
            `${environment.baseUrl}/category-management/add-subcategory`,
            payLoad
        );
    }

    //get all job categories
    getAllJobCategories(page: number, pageSize: number) {
        return this.httpClient.get(
            `${environment.baseUrl}/category-management/get-categories?page=${page}&pageSize=${pageSize}`
        );
    }
    getAllJobCategoriesForFilter() {
        return this.httpClient.get(
            `${environment.baseUrl}/category-management/get-categories`
        );
    }

    //get all sub-categories of a category
    getAllSubCategories(id: any) {
        return this.httpClient.get(
            `${environment.baseUrl}/category-management/get-subcategories-of-category/${id}`
        );
    }
  
    //get dummy data of countries
    getAllDummyDataOfCountries() {
            return this.httpClient.get(
                `${environment.baseUrl}/candidate-job-details-management/get-recent-job-info`
            );
        }

    //delete job category
    deleteJobCategory(id: any) {
        return this.httpClient.delete(
            `${environment.baseUrl}/category-management/delete-category/${id}`
        );
    }

    //delete job sub category
    deleteJobSubCategory(id: any) {
        return this.httpClient.delete(
            `${environment.baseUrl}/category-management/delete-subcategory/${id}`
        );
    }

    //update category
    updateCategory(id: any, payload: any) {
        return this.httpClient.put(
            `${environment.baseUrl}/category-management/update-category/${id}`,
            payload
        );
    }

    //update sub category
    updateSubCategory(id: any, payload: any) {
        return this.httpClient.put(
            `${environment.baseUrl}/category-management/update-subcategory/${id}`,
            payload
        );
    }

    //get all job categories
    getAllJobCategoriesWithSubCategories() {
        return this.httpClient.get(
            `${environment.baseUrl}/category-management/get-categories-with-subcategories`
        );
    }

    //get aptitade test questions
    getAllAptitudeTestQuestions() {
            return this.httpClient.get(
                `${environment.baseUrl}/aptitude-test-mangement/fetch-aptitued-test`
            );
        }

    //submit aptitade test questions
    submitAptitudeTestQuestions(payload:any) {
        return this.httpClient.post(
            `${environment.baseUrl}/aptitude-test-mangement/send-aptitued-test-result-to-candidate`,
            payload
        );
    }

}
