import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { inject } from '@angular/core';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(LocalStorageService)
  const router = inject(Router)
  const currentUserData = authService.getUserData()
  console.log('roleeeee', currentUserData)
  const expectedRole = route.data['expectedRole'];

  // Check if user's role matches the expected role
  if ((currentUserData?.body?.role || currentUserData?.role) !== expectedRole) {
    router.navigate(['/management-module/login']);
    return false;
  }
  return true;
};


