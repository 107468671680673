import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

@Component({
    selector: "app-validation-error",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./validation-error.component.html",
    styleUrl: "./validation-error.component.scss",
})
export class ValidationErrorComponent {
    @Input() control: AbstractControl | null = null;
    @Input() customMessages: { [key: string]: string } = {};
    get errorMessage() {
        if (
            this.control &&
            this.control.errors &&
            (this.control.dirty || this.control.touched)
        ) {
            if (this.control.errors?.required) {
                return (
                    this.customMessages["required"] || "This field is required."
                );
            }
            if (this.control.errors.email) {
                return (
                    this.customMessages["email"] ||
                    "Please enter a valid email address."
                );
            }
            if (this.control.errors.pattern) {
                return (
                    this.customMessages["pattern"] ||
                    "Please enter a valid phone number."
                );
            }
            if (this.control.errors.businessEmail) {
                return (
                    this.customMessages["businessEmail"] ||
                    "Please enter a valid business email address."
                );
            }
     
        return null;
    }
}
}