import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { AuthActions } from "./auth.actions";
export interface AuthStateModel {
    userData: any | null;
}
@State<AuthStateModel>({
    name: "Auth",
    defaults: {
        userData: null,
    },
})


@Injectable()

export class AuthState {

    @Action(AuthActions.Register)
    registerUser(
        ctx: StateContext<AuthStateModel>,
        action: AuthActions.Register
    ) {
        ctx.patchState({
            userData: action.payload,
        });    
    }


    @Action(AuthActions.UpdateUser)
    updateUser(ctx: StateContext<AuthStateModel>, action: AuthActions.UpdateUser) {
        ctx.patchState({
            userData: action.payload,
        });
    }
    


    @Action(AuthActions.Login)
    login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
        ctx.patchState({
            userData: action.payload,
        });
    }

    @Selector()
    static getUserData(state: AuthStateModel): any {
        console.log('statekadata',state.userData)
        return state.userData;
    }

}
