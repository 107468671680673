import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxExtendedPdfViewerComponent , pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { ToastrService } from 'ngx-toastr';
import SignaturePad from 'signature_pad';
import { JobCreateService } from 'src/app/shared/services/job-create.service';

@Component({
  selector: 'app-tfn-contract',
  templateUrl: './tfn-contract.component.html',
  styleUrl: './tfn-contract.component.scss'
})
export class TfnContractComponent {

  public signaturePad: SignaturePad | any;
  contractForm : FormGroup | any
  public signatureNeeded = false;
  generatedImage: string | null = null;
  public sidebarVisible = true;
  @ViewChild('canvas') canvasEl: ElementRef | any; 
  pdfSrc = 'assets/pdf/Magnus Recruitment - Employment Agreement - Casual.pdf';
  email: string;
  loader: boolean = false;
  signatureError: string;
  @ViewChild('pdfViewer') pdfViewer!: NgxExtendedPdfViewerComponent;
  @ViewChild('pdfViewerContainer') pdfViewerContainer!: ElementRef;
  candidateDetail: any = {};
  candidateId: string;
  candidateContractDetail: any = {};
 constructor(private fb: FormBuilder,
            private route: ActivatedRoute,
            private jobCreateService: JobCreateService,
            private readonly ngxToaster: ToastrService,
            private router: Router,
    ){
  this.pdfSrc;
  pdfDefaultOptions.doubleTapZoomFactor = '150%'; 
  pdfDefaultOptions.maxCanvasPixels = 4096 * 4096 * 5;
  this.initContractForm()
  }


  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
    this.candidateId = this.route.snapshot.paramMap.get('id')
    if (this.email) {
      this.getCandidateDetail();
      this.getCandidateContractDetail()
    }
  }


  initContractForm() {
    this.contractForm = this.fb.group({
      candidateName: [""],
      title: [""],
      gender: [""],
      surName: [""],
      givenName: [""],
      dOB: [""],
      tfn: [""],
      abn: [""],
      unitAndStreet: [""],
      suburb: [""],
      state: [""],
      postCode: [""],
      superFundName: [""],
      superFundABN: [""],
      superFundMemberShip: [""],
      commencementDate:[{ value: '', disabled: true }],
      endDate:[{ value: '', disabled: true }],
      amount:[{ value: '', disabled: true }],
      directorName: [""],
      companyName: [""],
      phoneNumber: [""]
    });
  }

  ngAfterViewInit() {
    if (this.canvasEl) {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    }
  }

  startDrawing(event: any) {
    if (this.signaturePad) {
      this.signatureNeeded = true;
    }
  }


  moved(event: any) {
    console.log(event)
    if (this.signaturePad) {
    }
  }

  clearPad(event: any) {
    event.preventDefault();
    if (this.signaturePad) {
      this.signaturePad.clear();
      this.signatureNeeded = false;
    }
  }

  dataURLToBlob(dataURL: string): Blob {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }


      //get candidate details
      getCandidateDetail() {
        this.jobCreateService.getCandidateDetailByEmail(
          this.email
            )
            .subscribe((res: any) => {
              this.candidateDetail = res?.body
              if (this.candidateDetail) {
                const fullName = `${this.candidateDetail.firstName || ''} ${this.candidateDetail.middleName || ''} ${this.candidateDetail.lastName || ''}`.trim();
        
                  this.contractForm.patchValue({
                  candidateName: fullName,
                  givenName: this.candidateDetail.firstName || '',
                  surName: this.candidateDetail.lastName || '',
                  dOB: this.candidateDetail.dob || '',
                  phoneNumber: this.candidateDetail.phoneNumber || '',
                });
              }
            });
    }


          //get candidate contract details
          getCandidateContractDetail() {
            this.jobCreateService.getCandidateContractDetail(
              this.email
                )
                .subscribe((res: any) => {
                  this.candidateContractDetail = res?.body
                  if (this.candidateContractDetail) {      
                      this.contractForm.patchValue({
                        amount: this.candidateContractDetail.amount || '',               
                        commencementDate: this.candidateContractDetail.commencementDate || '',
                        endDate: this.candidateContractDetail.endDate || ''
                    });
                  }
                });
          }


  payload() {
    const payLoad = new FormData();
    const address = `${this.contractForm.get("unitAndStreet").value}, ${this.contractForm.get("suburb").value}, ${this.contractForm.get("state").value}, ${this.contractForm.get("postCode").value}`;
    payLoad.append("candidateName", this.contractForm.get("candidateName").value);
    payLoad.append("candidateId", this.candidateId);
    payLoad.append("title", this.contractForm.get("title").value);
    payLoad.append("gender", this.contractForm.get("gender").value);
    payLoad.append("surName", this.contractForm.get("surName").value);
    payLoad.append("givenName", this.contractForm.get("givenName").value);
    payLoad.append("dOB", this.contractForm.get("dOB").value);
    payLoad.append("address", address); 
    payLoad.append("tfn", this.contractForm.get("tfn").value);
    payLoad.append("abn", this.contractForm.get("abn").value);
    payLoad.append("unitAndStreet", this.contractForm.get("unitAndStreet").value);
    payLoad.append("suburb", this.contractForm.get("suburb").value);
    payLoad.append("state", this.contractForm.get("state").value);
    payLoad.append("postCode", this.contractForm.get("postCode").value);
    payLoad.append("superFundName", this.contractForm.get("superFundName").value);
    payLoad.append("superFundABN", this.contractForm.get("superFundABN").value);
    payLoad.append("superFundMemberShip", this.contractForm.get("superFundMemberShip").value);
    payLoad.append("companyName", this.contractForm.get("companyName").value);
    payLoad.append("phoneNumber", this.contractForm.get("phoneNumber").value);
    payLoad.append("email", this.email);
    payLoad.append("contractType", "TFN contract");
    payLoad.append("directorName" , "Shamima Yasmin")
    payLoad.append("commencementDate", this.contractForm.get("commencementDate").value);
    payLoad.append("endDate", this.contractForm.get("endDate").value);
    payLoad.append("amount", this.contractForm.get("amount").value);

    if (this.signaturePad && !this.signaturePad.isEmpty()) {
      const dataURL = this.signaturePad.toDataURL();
      const signatureBlob = this.dataURLToBlob(dataURL);
      payLoad.append("candidate-contract-signature", signatureBlob, "signature.png");
    }
    return payLoad;
  }


  submit() {
    this.loader = true;
    this.signatureError = '';
    if (this.contractForm.invalid || (this.signaturePad && this.signaturePad.isEmpty())) {
      this.touchFormControls();
      if (this.signaturePad && this.signaturePad.isEmpty()) {
        this.signatureError = "Please provide your signature.";
        this.loader = false
        this.ngxToaster.error(this.signatureError)
      }
      return;
    }
    this.jobCreateService.sendContract(this.payload()).subscribe({
        next: (response: any) => {
            const { result, isData } = response;
            if (isData === true) {
                this.loader = false;
                this.ngxToaster.success(result);
                this.router.navigate(['/thank-you']);
            } else if (isData === false) {
                this.loader = false;
                this.ngxToaster.error(result);
            }
        },
        error: (error) => {
            const errorMessage = error?.error?.result;
            this.loader = false;
            this.ngxToaster.error(errorMessage);
        },
    });
  }



  touchFormControls() {
    Object.keys(this.contractForm.controls).forEach((key) => {
        this.contractForm.get(key)?.markAsTouched();
    });
}
}
