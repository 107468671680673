<div class="container">
    <div class="row">
        <div class="col text-center">
            <h1>
                Thank You!
            </h1>
            <p>
                for contract Please check your Email ....
            </p>
        </div>
    </div>
</div>
