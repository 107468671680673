import { Component, OnInit } from '@angular/core';
import { JobTitleService } from 'src/app/shared/dummy-data/job-create.service';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {

  constructor() { }



  ngOnInit(): void {
  }





}
