
import { Component, OnInit } from "@angular/core";
import {
    Router,
    NavigationStart,
    NavigationCancel,
    NavigationEnd,
    ActivatedRoute,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
import { AuthService } from "./store/auth/auth.service";
import { LocalStorageService } from "./shared/services/local-storage.service";
import { CandidateEmployerListService } from "./shared/services/candidate-employer-list.service";
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    userData: any = {};
    questions: any = [];
    hideLayout = false;
    
    constructor(
        private router: Router,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private activeRoute:ActivatedRoute,
        private readonly candidateEmployerListService:CandidateEmployerListService
    ) {}

    ngOnInit() {
        console.log('workdss')
        this.recallJsFuntions();
        // this.getCandidateDetails()
        this.userData = this.localStorageService.getUserData();
        // if (this.userData?.body?._id && this.userData?.body?.role === 'admin' ||  this.userData?.body?.role === 'employee') {
        //     this.authService.updateUser(this.userData);
        // }
        if (this.userData?.body?._id || this.userData) {
            
            this.authService.updateUser(this.userData);
        }
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              // Check if the URL contains any of the specified routes
              this.hideLayout = ['/acn-contract', '/abn-contract', '/tfn-contract' , '/thank-you']
                .some(route => event.url.includes(route));
            }
          });
    }


    // getCandidateDetails() {
    //     const candidateId = this.localStorageService.getUserData();
    //     console.log('candidateId', candidateId?.body?._id);
      
    //     // Pehle check karein agar `this.userData` already exist karta hai toh API call NA karein
    //     if (this.userData) {
    //       console.log('User data already exists, skipping API call.');
    //       return;
    //     }
      
    //     // Agar `this.userData` exist nahi karta, tab API call karein
    //     this.candidateEmployerListService.getCandidateById(candidateId?.body?._id).subscribe((res: any) => {
    //       this.authService.updateUser(res?.body?.findUniqueCandidateInfo);
    //     });
    //   }



    recallJsFuntions() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                $(".loader").fadeIn("slow");
            }
        });
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                $.getScript("../assets/js/custom.js");
                $(".loader").fadeOut("slow");
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
